import api from "../../../utils/Api"

// Action type
export const GET_DONATIONS = "donations/GET"
export const CLEAR_DONATIONS = "donations/CLEAR"
export const CREATE_DONATION = "donation/createStripeCheckoutSession"
export const GET_ONE_DONATION = "donation/getDonationById"
export const CREATE_EVENT_DONATION = "donation/createEventDonation"

// Action creator
export const getDonations = (donations) => {
    return {
        type: GET_DONATIONS,
        donations
    };
};

export const getOneDonation = (donation) => {
    return {
        type: GET_ONE_DONATION,
        donation
    };
};

export const clearDonations = () => {
    return {
        type: CLEAR_DONATIONS
    }
}

export const createDonation = (donation) => {
    return {
        type: CREATE_DONATION,
        donation
    };
};


export const createEventDonation = (donation) => {
    return {
        type: CREATE_EVENT_DONATION,
        donation
    };
};

//  Thunk
export const getOrganizationDonationsThunk = () => async dispatch => {
    try {
        const response = await api.get("/donation/donor/list/");
        if (response.ok) {
            dispatch(getDonations(response.data))
        }
    } catch (error) {
        return { error };
    }
};

export const getDonationByIdThunk = (donationId) => async dispatch => {
    try {
        const response = await api.getNonAuth(`/donation/?donation_id=${donationId}`);
        if (response.ok) {
            dispatch(getOneDonation(response.data))
        }
    } catch (error) {
        throw error
    }
};

export const getMyDonationsThunk = () => async dispatch => {
    try {
        const response = await api.get("/donation/my-donations/");
        dispatch(getDonations(response.data))
    } catch (error) {
        return error
    }
};

export const createDonationThunk = (data) => async dispatch => {
    try {
        const response = await api.postNonAuth("/donation/", data);
        if (response.ok && response.status === 201) {
            // Dispatch success action
            dispatch(createDonation(response.data));
            return { ...response.data, isSuccess: true };
        } else {
            // Handle error without dispatching an action
            return { ...response.data, isSuccess: false };
        }
    } catch (error) {
        // Handle error without dispatching an action
        if (error.response && error.response.data) {
            return { ...error.response.data, isSuccess: false };
        }
        return { error, isSuccess: false };
    }
};

export const createEventDonationThunk = (data) => async dispatch => {
    try {
        const response = await api.postNonAuth("/donation/event/", data);
        if (response.ok && response.status === 201) {
            // Dispatch success action
            dispatch(createEventDonation(response.data));
            return { ...response.data, isSuccess: true };
        } else {
            // Handle error without dispatching an action
            return { ...response.data, isSuccess: false };
        }
    } catch (error) {
        // Handle error without dispatching an action
        if (error.response && error.response.data) {
            return { ...error.response.data, isSuccess: false };
        }
        return { error, isSuccess: false };
    }
};
