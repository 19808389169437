import * as CalendarActions from "../../actions/calendarAction";

// ** Initial State
const initialState = {
    events: [],
    forms: [],
    contacts: [],
    projects: [],
    selectedEvent: {},
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
}


const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CalendarActions.FETCH_EVENTS:
      return { ...state, events: action.events };

    case CalendarActions.GET_PROJECTS:
      let projects = action.projects.map((project) => ({
        ...project,
        label: project.name,
        value: project.pk,
      }));
      return { ...state, projects };

    case CalendarActions.ADD_EVENT_SUCCESS:
      return { ...state };

    case CalendarActions.FETCH_FORMS:
      return { ...state, ...action };

    case CalendarActions.DELETE_EVENT:
      return { ...state };

    case CalendarActions.UPDATE_EVENT_SUCCESS:
      return { ...state };

    case CalendarActions.UPDATE_FILTERS:
      const filterIndex = state.selectedCalendars.findIndex(
        (i) => i === action.filter
      );
      if (state.selectedCalendars.includes(action.filter)) {
        return {
          ...state,
          selectedCalendars: [
            ...state.selectedCalendars.slice(0, filterIndex),
            ...state.selectedCalendars.slice(filterIndex + 1),
          ],
        };
      } else {
        return {
          ...state,
          selectedCalendars: [...state.selectedCalendars, action.filter],
        };
      }
    case CalendarActions.UPDATE_ALL_FILTERS:
      const value = action.value;
      let selected = value
        ? ["Personal", "Business", "Family", "Holiday", "ETC"]
        : [];
      return { ...state, selectedCalendars: selected };

    case CalendarActions.SELECT_EVENT:
      return { ...state, selectedEvent: action.event };

    default:
      return state;
  }
};

export default calendarReducer;
