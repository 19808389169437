import api from '../../../utils/Api';

export const GET_ORG_MEMBERS = 'members/getOrgMembers';
export const GET_SELECTED_MEMBER = 'members/getSelectedMember';
export const CLEAR_SELECTED_MEMBER = 'members/clearSelectedMember';
export const EDIT_MEMBER = 'members/editMember';
export const EDIT_MEMBER_ROLE = 'members/editMemberRole';

export const getOrgMembers = (data, totalPages, params) => ({
  type: GET_ORG_MEMBERS,
  payload: { data, totalPages, params },
});

export const selectedMemberAction = member => ({
  type: GET_SELECTED_MEMBER,
  member,
});

export const clearSelectedMember = () => {
  return {
    type: CLEAR_SELECTED_MEMBER,
  };
};

export const editMemberAction = member => ({
  type: EDIT_MEMBER,
  member,
});

export const editMemberRoleAction = (memberId, role, project) => ({
  type: EDIT_MEMBER_ROLE,
  payload: { memberId, role, project },
});

// ** Get data on page or row change
export const getOrgMembersThunk = params => async dispatch => {
  const url = '/member/';
  try {
    const response = await api.get(url, params);
    dispatch(getOrgMembers(response.data.members, response.data.total, params));
    return response;
  } catch (error) {
    throw error;
  }
};

export const editMemberThunk = member => async dispatch => {
  try {
    const response = await api.put('/member/team/', member);
    if (response.ok) {
      dispatch(editMemberAction(response.data));
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const editMemberRoleAndTeamThunk = data => async dispatch => {
  const { memberId, teams_and_roles } = data;
  try {
    const response = await api.put(`/member/update/${memberId}/`, { teams_and_roles });
    if (response.ok) {
      dispatch(editMemberAction(response.data));
      return response;
    }
  } catch (error) {
    throw error;
  }
};
