import { Upload } from 'react-feather';
import { UPDATE_IMPORT_DATA, UPDATE_IMPORT_PROGRESS } from '../../actions/importMemberAction';
  
  const initialState = {
    importData: '',
    importProgress: 0,
    memberLists: [],
    importedDataCount: undefined,
  };
  
  const importMemberReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_IMPORT_DATA:
        return { 
          ...state, 
          importData: action.payload 
        }
      case UPDATE_IMPORT_PROGRESS:
          return { 
            ...state, 
            importProgress: action.payload 
        }
  
      default:
        return state;
    }
  };
  
  export default importMemberReducer;
  