import api from '../../../utils/Api';

// ** Get data on page or row change
export const GET_RSVP = 'events/GET_RSVP';
export const GET_EVENTS = 'events/GET_EVENTS';
export const SELECT_EVENT_LIST = 'events/SELECT_EVENT_LIST';
export const GET_PUBLIC_EVENT_BY_SLUG = 'events/getPublicEventBySlug';
export const GET_UPCOMING_PUBLIC_EVENTS = 'events/getUpcomingPublicEvents';
export const CLEAR_EVENT_DATA = 'CLEAR_EVENT_DATA';

export const getRsvpAction = (data, event_id, params) => {
  return {
    type: GET_RSVP,
    payload: { data, event_id, params },
  };
};

// Action Creator for getting events
export const getEventsAction = (data, params) => {
  return {
    type: GET_EVENTS,
    payload: { data, params },
  };
};

export const getUpcomingPublicEventsAction = (data, params) => {
  return {
    type: GET_UPCOMING_PUBLIC_EVENTS,
    payload: { data, params },
  };
};

export const selectEventList = listData => {
  return {
    type: SELECT_EVENT_LIST,
    payload: listData,
  };
};

export const clearEventData = () => {
  return {
    type: CLEAR_EVENT_DATA,
  };
};

export const getPublicEventAction = eventData => {
  return {
    type: GET_PUBLIC_EVENT_BY_SLUG,
    payload: eventData,
  };
};

// Thunk for getting RSVP data
export const getData = (event_id, params) => {
  return async dispatch => {
    try {
      const response = await api.get(`/event/rsvp/${event_id}/`, params);
      if (response.ok) {
        dispatch(getRsvpAction(response.data, event_id, params));
      } else {
        const errorMessage = response.data?.error
          ? response.data.error
          : 'Unexpected error occurred while fetching rsvp event data. Please try again.';
        throw new Error(errorMessage);
      }
    } catch (err) {
      return err;
    }
  };
};

// Thunk for getting events
export const getEvents = params => {
  return async dispatch => {
    try {
      const response = await api.getNonAuth('/event/public/', params);
      if (response.ok) {
        if (params.is_upcoming) {
          dispatch(getUpcomingPublicEventsAction(response.data, params));
        } else {
          dispatch(getEventsAction(response.data, params));
        }
      } else {
        const errorMessage = response.data?.error
          ? response.data.error
          : 'Unexpected error occurred while fetching public events. Please try again.';
        throw new Error(errorMessage);
      }
    } catch (err) {
      return err;
    }
  };
};

export const getPublicEventBySlugThunk = slug => {
  return async dispatch => {
    try {
      const response = await api.getNonAuth(`/event/public/${slug}/`);
      if (response.ok) {
        dispatch(getPublicEventAction(response.data));
        return response.data;
      } else {
        const errorMessage = response.data?.error
          ? response.data.error
          : 'Unexpected error occurred while fetching public event data. Please try again.';
        throw new Error(errorMessage);
      }
    } catch (err) {
      throw err;
    }
  };
};

export const eventRegisterThunk = (slug, data) => async dispatch => {
  try {
    const response = await api.postNonAuth(`/event/rsvp/create/${slug}/`, data);
    if (response.ok) {
      return response;
    } else {
      const errorMessage = response.data?.error
        ? response.data.error
        : 'Unexpected error occurred while register event. Please try again.';
      throw new Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};
