import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

const ability = new Ability(initialAbility)

export const updateUserAbility = (user) => {
  let userAbility = [];
        user?.permissions.forEach(permission => {
            userAbility.push({
                action: 'manage',
                subject: permission
            })
        })

  ability.update(userAbility)

  return userAbility
}

export default ability;
