import api from "./Api";
import DataStore from "./DataStore";
import { updateUserAbility } from "../configs/acl/ability";

export const setupTokenAndLoad = (token, user) => {
  const userAbility = updateUserAbility(user);

  try {
    DataStore.set("access_token", token.access);
    DataStore.set("userData", {
      ...user,
      ability: userAbility,
    });
  } catch (e) {
    throw e;
  }
  try {
    api.initAuth(token.access);
  } catch (e) {
    throw e;
  }
};
