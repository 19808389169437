// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { ToastContainer } from 'react-toastify';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';
import { IntlProviderWrapper } from './utility/context/Internationalization';
import { FormSlideInProvider } from './utility/context/FormSlideInContext';
import { ModalProvider } from './utility/context/ModalContext';
// ** Spinner (Splash Screen)'
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** Fake Database
import './@fake-db';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import GoogleOAuth from './views/pages/authentication/GoogleOAuth';

// If the hostname does not include 'stage' for staging environment, then the alternative tracking ID is for the production environment.
const hostname = window.location.hostname;
let gaTrackingId;

if (hostname.includes('stage')) {
  gaTrackingId = 'G-RZC4YPVW5X';
} else if (hostname.includes('goodworkhub')) {
  gaTrackingId = 'G-79ZQ27YXTH';
}

ReactGA.initialize(gaTrackingId);
// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/oauth/google">
          <GoogleOAuth />
        </Route>
        <Route path="/">
          <Provider store={store}>
            <Suspense fallback={<Spinner />}>
              <AbilityContext.Provider value={ability}>
                <ModalProvider>
                  <FormSlideInProvider>
                    <ThemeContext>
                      <IntlProviderWrapper>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                      </IntlProviderWrapper>
                    </ThemeContext>
                  </FormSlideInProvider>
                </ModalProvider>
              </AbilityContext.Provider>
            </Suspense>
          </Provider>
        </Route>
      </Switch>
    </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
