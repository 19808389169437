import { param } from 'jquery';
import {
  GET_ALL_PENDING_INVITATIONS,
  SELECTED_INVITATIONS,
  REMOVE_PENDING_VOLUNTEER,
} from '../../actions/invitationsAction';

const initialState = {
  data: [],
  total: 0,
  selectedInvitations: [],
};

const invitationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PENDING_INVITATIONS:
      return {
        ...state,
        data: action.pendingInvitations.pending_volunteers,
        total: action.pendingInvitations.total,
      };
    case SELECTED_INVITATIONS:
      return { ...state, selectedInvitations: action.invitationList };
    case REMOVE_PENDING_VOLUNTEER:
      return {
        ...state,
        data: state.data.filter(volunteer => volunteer.pk !== action.payload),
        total: state.total - 1,
      };
    default:
      return state;
  }
};

export default invitationsReducer;
