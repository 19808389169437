import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { isTrustedURL } from "../../../helperFunctions/security/urlValidation";
import { sendMessageToParent } from "../../../helperFunctions/communication/parentCommunication";
import { Button } from "reactstrap";
import googleSVG from '../../../assets/images/pages/login/googleRainbow.svg'

const GoogleOAuth = () => {
  // This component is only to be used as an iframe. The use case for this component is to fetch the token from Google OAuth
  // This is the URL of the parent page, which we need both to send a message to the parent page and to add the subdomain of the parent page to the dispatch
  const parentUrl = document.referrer;
  const defaultErrorMessage =
    "Oops! Something went wrong while logging in with Google. Please try again later.";

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) =>
      sendMessageToParent(
        JSON.stringify({ token: tokenResponse.access_token }),
        parentUrl
      ),
    onError: () =>
      sendMessageToParent(
        JSON.stringify({ error: defaultErrorMessage }),
        parentUrl
      ),
  });

  useEffect(() => {
    // Check if the page is being accessed in an iframe
    if (window.self === window.top) {
      // If not, redirect the user to another page or display an error message
      window.location.href = "/error";
      return;
    }

    // Check if the parent is trusted
    if (!isTrustedURL(parentUrl)) return;
  }, []);

  return (
    <Button
      outline
      color="primary"
      onClick={async () => {
        handleGoogleLogin()
      }}
      className="d-flex align-items-center justify-content-center w-100 bg-white"
      style={{ position: "relative", width: '100%', height: '3.5rem'}}
    >
      <img src={googleSVG} style={{ height: "24px", marginRight: "8px" }} />
      Continue with Google
    </Button>
  );
};

export default GoogleOAuth;
