// ** Initial State
const initialState = {
    defaultContacts: [],
    contacts: []
}

const contacts = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CONTACTS_COMMON':
            // ** If currentMail is not null / undefined then find and set currentMail
            return {
                ...state,
                contacts: action.contacts
            }
        case 'GET_CONTACTS_DEFAULT':
            // ** If currentMail is not null / undefined then find and set currentMail
            return {
                ...state,
                defaultContacts: action.contacts
            }
        default:
            return state
    }
}

export default contacts
