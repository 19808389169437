// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import navbar from './navbar';
import layout from './layout';
import donationReducer from './donationReducer';
import organizationReducer from './organizationReducer';
import members from './memberReducer';
import importMemberForms from '@src/views/hub-settings/store/reducer';
import contacts from '@src/views/contacts/store/reducer';
import email from './messagesReducer';
import files from '@src/views/files/store/reducer';
import dashboard from './dashboardReducer';
import events from './eventReducer';
import planning from './planningReducer';
import organizationProfileReducer from '../reducers/organizationProfileReducer';
import calendarReducer from '../../redux/reducers/calendarReducer';
import importMemberReducer from './importMemberReducer';
import teamsReducer from './teamsReducer';
import GrantReducer from './grantReducer';
import formReducer from './formReducer';
import invitationsReducer from './invitationReducer';

const rootReducer = combineReducers({
  auth,
  email,
  members,
  layout,
  events,
  contacts,
  importMemberForms,
  files,
  navbar,
  dashboard,
  donations: donationReducer,
  organizations: organizationReducer,
  planning,
  organizationProfile: organizationProfileReducer,
  calendar: calendarReducer,
  importMember: importMemberReducer,
  teams: teamsReducer,
  grants: GrantReducer,
  forms: formReducer,
  invitations: invitationsReducer,
});

export default rootReducer;
