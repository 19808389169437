// ** Initial State
import {
  GET_RSVP,
  GET_EVENTS,
  SELECT_EVENT_LIST,
  GET_PUBLIC_EVENT_BY_SLUG,
  GET_UPCOMING_PUBLIC_EVENTS,
  CLEAR_EVENT_DATA,
} from '../../actions/eventAction';

const initialState = {
  publicEvent: {},
  allData: [],
  projectList: [],
  data: [],
  publicEvents: [],
  upcomingPublicEvents: [],
  total: 1,
  params: {},
  selectedUser: null,
  selectedEventList: [],
};

const events = (state = initialState, action) => {
  switch (action.type) {
    case GET_RSVP:
      return { ...state, data: action.payload.data };
    case CLEAR_EVENT_DATA:
      return { ...state, data: [] };
    case GET_EVENTS:
      return { ...state, publicEvents: action.payload.data };
    case GET_PUBLIC_EVENT_BY_SLUG:
      return { ...state, publicEvent: action.payload };
    case SELECT_EVENT_LIST:
      return { ...state, selectedEventList: action.payload };
    case GET_UPCOMING_PUBLIC_EVENTS:
      return { ...state, upcomingPublicEvents: action.payload.data };
    default:
      return state;
  }
};
export default events;
