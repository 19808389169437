import axios from 'axios';
import { merge } from 'jquery';
import api from '../../../utils/Api';

// Action type constants
export const FETCH_FORMS = 'FETCH_FORMS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_ALL_FILTERS = 'UPDATE_ALL_FILTERS';
export const SELECT_EVENT = 'SELECT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
// Action creators
export const fetchFormsSuccess = forms => ({
  type: FETCH_FORMS,
  forms,
});

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS,
  projects,
});

export const fetchEventsSuccess = events => ({
  type: FETCH_EVENTS,
  events,
});

export const addEventSuccess = () => ({
  type: ADD_EVENT_SUCCESS,
});

export const updateEventSuccess = () => ({
  type: UPDATE_EVENT_SUCCESS,
});

export const updateFilters = filter => ({
  type: UPDATE_FILTERS,
  filter,
});

export const updateAllFilters = value => ({
  type: UPDATE_ALL_FILTERS,
  value,
});

export const selectEvent = event => ({
  type: SELECT_EVENT,
  event,
});

export const deleteEventSuccess = event => ({
  type: DELETE_EVENT,
  event,
});

// Thunk actions
export const fetchForms = () => async dispatch => {
  try {
    const response = await api.get('/form/');
    if (response.ok) {
      dispatch(fetchFormsSuccess(response.data));
    }
  } catch (error) {
    // Handle errors
    return { message: 'An error occurred' };
  }
};

export const getProjects = () => async dispatch => {
  try {
    const response = await api.get('/organization/project/');
    if (response.ok) {
      dispatch(getProjectsSuccess(response.data));
    }
  } catch (error) {
    // Handle errors
    return { message: 'An error occurred' };
  }
};

export const fetchEvents = params => async (dispatch, getState) => {
  try {
    const response = await api.get('/event/', params);
    if (response.ok) {
      let mergedEventData = [];
      response.data.forEach(event => {
        //order is significant, otherwise id will go missing{ ...event, ...event.event }
        if (!event.cancelled) {
          delete event.event['start']; //This will be the start date of first event of recurring event
          delete event.event['end']; //This will be the start date of first event of recurring event, removing before merging

          let parentId = event.event.id;
          let occuranceId = event.id;
          if (!occuranceId) {
            occuranceId = 0;
          }
          let merged = { ...event, ...event.event, parentId, occuranceId, id: `${parentId}__${occuranceId}` };
          delete merged['event'];

          mergedEventData.push(merged);
        }
      });
      dispatch(fetchEventsSuccess(mergedEventData));
    } else {
      return { message: 'An error occurred' };
    }
  } catch (error) {
    // Handle errors
    return { message: 'An error occurred' };
  }
};

export const addEvent = event => async dispatch => {
  try {
    await api.post('/event/', event);
    dispatch(addEventSuccess());
    dispatch(fetchEvents());
    return { success: true, message: 'Event Added' };
  } catch (error) {
    if (error.data && error.data.error) {
      return { success: false, message: error.data.error };
    } else {
      return { success: false, message: 'An error occurred' };
    }
  }
};

export const updateEvent = event => async dispatch => {
  try {
    await api.post('/event/', event);
    dispatch(updateEventSuccess());
    dispatch(fetchEvents());
    return { success: true, message: 'Event Updated' };
  } catch (error) {
    dispatch(fetchEvents());
    if (error.data && error.data.error) {
      return { success: false, message: error.data.error };
    } else {
      return { success: false, message: 'An error occurred' };
    }
  }
};

export const removeEvent = (id, allOccurrences) => async dispatch => {
  const url = '/event/';
  try {
    const response = await api.delete(url, { id, all_occurrences: allOccurrences });
    if (response.ok && response.data && response.data.message) {
      dispatch(fetchEvents());

      // Return the successful response
      return { isSuccess: true, message: response.data.message };
    } else {
      // Handle non-okay response here if needed
      return { isSuccess: false, message: 'Delete event error' };
    }
  } catch (error) {
    // Handle error without dispatching an action
    const errorMessage = error.data?.error || 'An unexpected error occurred';
    return { isSuccess: false, message: errorMessage };
  }
};

export const updateFilter = filter => {
  try {
    return (dispatch, getState) => {
      dispatch(updateFilters(filter));
      dispatch(fetchEvents(getState().calendar.selectedCalendars));
    };
  } catch (error) {
    // Handle error without dispatching an action
    return { message: 'An error occurred' };
  }
};

export const updateAllFilter = value => {
  try {
    return (dispatch, getState) => {
      dispatch(updateAllFilters(value));
      dispatch(fetchEvents(getState().calendar.selectedCalendars));
    };
  } catch (error) {
    // Handle error without dispatching an action
    return { message: 'An error occurred' };
  }
};
