import Storage from 'store';
import Cookies from 'js-cookie';
import config from '../Config';

// This is to see what domain to store the cookie for
let domain = "." + config.landingDomain.replace(/^https?:\/\//, "").replace(/:[0-9]+/, "").replace(/\/$/, "");

class DataStore {
    static set(key, val) {
        let value = null;
        try {
            // If the key is an access token, set it in cookies
            if (key === "access_token") Cookies.set(key, val, { domain: domain, expires: 7 });
            else value = Storage.set(key, val);
        } catch (error) {
            console.error(`Error saving data ${key} ${val}`);
            console.error(JSON.stringify(error));
        }
        return value;
    }

    static get(key) {
        let value = null;
        try {
            if (key === "access_token") value = Cookies.get("access_token")
            else value = Storage.get(key);
        } catch (error) {
            // Error saving data
        }
        return value;
    }

    static remove(key) {
        Cookies.remove("access_token", { domain: domain})
    }

    getAll() {
        let value = null;
        try {
            value = Storage.getAllKeys();
        } catch (error) {
            // Error saving data
        }

        return value;
    }

    getAllKeys() {
        let keys = []
        try {
            keys = Storage.getAllKeys()
        } catch (e) {
            console.log(e.message);
        }
        // example console.log result:
        // ['@MyApp_user', '@MyApp_key']
    }
}


export default DataStore;
