// Check if url is trusted
export const isTrustedURL = (url) => {
  // List of trusted domains with wildcard subdomains
  const trustedDomains = [
    /^http:\/\/(\w+\.)?localhost:3000\//,
    /^https?:\/\/(\w+\.)?stage-gwh\.xyz\//,
    /^https?:\/\/(\w+\.)?goodworkhub\.com\//,
  ];
  // Check if the url matches any of the trusted domains
  return trustedDomains.some(domainPattern => domainPattern.test(url));
};
