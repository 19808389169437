import React, { createContext, useState, useEffect } from 'react';

export const FormSlideInContext = createContext();

export const FormSlideInProvider = ({ children }) => {
  const [formSlideInOpen, setFormSlideInOpen] = useState(false);
  const [activeSlideIn, setActiveSlideIn] = useState('');

  const toggleFormSlideIn = (slideInName = '') => {
    // Set the name of the active slide-in
    setActiveSlideIn(slideInName);
    // Toggle the slide-in open/close state
    setFormSlideInOpen(slideInName !== activeSlideIn || !formSlideInOpen);
  };

  // Close the slide-in and reset the activeSlideIn state
  const closeFormSlideIn = () => {
    setActiveSlideIn('');
    setFormSlideInOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (event.target.classList.contains('menu-item')) {
        closeFormSlideIn();
      }
    };

    if (formSlideInOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [formSlideInOpen, closeFormSlideIn]);

  return (
    <FormSlideInContext.Provider value={{ formSlideInOpen, activeSlideIn, toggleFormSlideIn, closeFormSlideIn }}>
      {children}
    </FormSlideInContext.Provider>
  );
};
