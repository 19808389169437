// ** Initial State
const initialState = {
  files: [],
  folders: [],
  projectList: [],
  contacts: [],
  total: 1,
  params: {},
  selectedUser: null
}

const files = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_FILES':
      return {
        ...state,
        files: action.files,
        folders: action.folders,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_FOLDER':
      return {
        ...state
      }
    case 'DELETE_FILE':
      return {
        ...state
      }
    case 'GET_CONTACTS':
      return {
        ...state,
        contacts: action.contacts
      }
    case 'GET_PROJECT':
      let projectList = []
      action.data.forEach(proj => {
        projectList.push({ label: proj.name, value: proj.pk })
      });
      return { ...state, projectList }
    default:
      return { ...state }
  }
}
export default files
