import api from '../../../utils/Api';

// Action types
export const FETCH_DASHBOARD_ORG_DATA = 'dashboard/FETCH_ORG_DATA';
export const GET_UPCOMING_EVENTS = 'dashboard/GET_UPCOMING_EVENTS';
export const GET_DASHBOARD_NUMBERS = 'dashboard/GET_NUMBERS';
export const GET_DASHBOARD_BANNERS = 'dashboard/GET_BANNERS';
export const CLEAR_DASHBOARD_DATA = 'dashboard/CLEAR_DATA';
export const CLEAR_UPCOMING_EVENTS = 'dashboard/CLEAR_UPCOMING_EVENTS';
export const EDIT_ANNOUNCEMENT = 'dashboard/EDIT_ANNOUNCEMENT';

// Action creators
export const fetchDashboardOrgDataAction = organization => {
  return {
    type: FETCH_DASHBOARD_ORG_DATA,
    organization,
  };
};

export const fetchUpcomingEventsAction = events => {
  return {
    type: GET_UPCOMING_EVENTS,
    events,
  };
};

export const getDashboardNumbersAction = numbers => {
  return {
    type: GET_DASHBOARD_NUMBERS,
    numbers,
  };
};

export const getDashboardBannersAction = banners => {
  return {
    type: GET_DASHBOARD_BANNERS,
    banners,
  };
};

export const clearDashboardDataAction = () => {
  return {
    type: CLEAR_DASHBOARD_DATA,
  };
};

export const clearUpcomingEventsAction = () => {
  return {
    type: CLEAR_UPCOMING_EVENTS,
  };
};

export const editAnouncementAction = announcement => {
  return {
    type: EDIT_ANNOUNCEMENT,
    announcement,
  };
};

// Thunks
export const fetchDashboardOrgDataThunk = () => async dispatch => {
  try {
    const response = await api.get('/organization/');
    if (response.ok) {
      dispatch(fetchDashboardOrgDataAction(response.data.organization));
      localStorage.setItem('orgData', JSON.stringify(response.data.organization));
      return response;
    } else {
      const errorMessage =
        response.data && response.data.error
          ? response.data.error
          : 'Unexpected error occurred while fetching organization data. Please try again.';
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Unexpected error occurred:', error);
    return error;
  }
};

export const fetchUpcomingEventsThunk = () => async dispatch => {
  try {
    const response = await api.get(`/dashboard/event/`);
    if (response.ok) {
      dispatch(fetchUpcomingEventsAction(response.data));
      return response;
    }
    throw new Error('Something went wrong with fetching upcoming events. Please try again later.');
  } catch (e) {
    throw e;
  }
};

export const fetchNumbers =
  (filter, isDefault = false) =>
  async dispatch => {
    try {
      const response = await api.get('/dashboard/stats/', { filter, default: isDefault, include_subscribers: true });
      if (response.ok) {
        dispatch(getDashboardNumbersAction(response.data));
      }
    } catch (error) {
      console.error('Error fetching numbers:', error);
    }
  };

export const fetchBanners =
  (filter, isDefault = false) =>
  async dispatch => {
    try {
      const response = await api.get('/dashboard/banner/', { filter, default: isDefault, include_subscribers: true });
      if (response.ok) {
        dispatch(getDashboardBannersAction(response.data));
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

export const updateDashboardAnnouncementThunk = payload => {
  return async dispatch => {
    try {
      const response = await api.put('/organization/', payload);
      if (response.ok) {
        dispatch(fetchDashboardOrgDataAction(response.data.organization));
        localStorage.setItem('orgData', JSON.stringify(response.data.organization));
        return response;
      } else {
        const errorMessage =
          response.data && response.data.error
            ? response.data.error
            : 'Unexpected error occurred while editing organization announcement. Please try again.';
        throw new Error(errorMessage);
      }
    } catch (error) {
      throw error;
    }
  };
};
