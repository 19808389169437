export default {
  //Test Mode
  // stripePublicKey: 'pk_test_51Lup0jG1vgiymCwVxGCNoxbzWqd0bvMTg5KUCHlK6mZEVAWZS5lafPAGlHEdmqaMlrEmYYi1ow4JkNKnYaN7raj300g4Kx5PB0',

  // Live Mode
  // stripePublicKey: "pk_live_51Lup0jG1vgiymCwVRWPYuE4SvMjAZQHxX8kcJBy4lB2heTXRtsDIlzcl4zwboavfAY69gVVQIHogKXBjiDNxuBWd000fHddoBE",

  ////// LOCAL //////
  // landingDomain: 'http://app.localhost:3000/',
  // baseDomain: 'http://localhost:3001/',
  // appDomain: 'http://app.localhost:3001/',
  // apiEndPoint: 'http://localhost:8085/',
  // awsPublicAssetsURL: 'https://gwh-static-css-js-files.s3.amazonaws.com',

  ////// DEV //////
  // landingDomain: 'https://dev-gwh.xyz/',
  // baseDomain: 'https://dev-gwh.xyz/',
  // appDomain: 'https://app.dev-gwh.xyz/',
  // apiEndPoint: 'https://api.dev-gwh.xyz/',
  // awsPublicAssetsURL: 'https://gwh-dev-static-css-js-files.s3.amazonaws.com',

  ////// STAGE //////
  // landingDomain: 'https://stage-gwh.xyz/',
  // baseDomain: 'https://stage-gwh.xyz/',
  // appDomain: 'https://app.stage-gwh.xyz/',
  // apiEndPoint: 'https://api.stage-gwh.xyz/',
  // awsPublicAssetsURL: 'https://gwh-stage-static-css-js-files.s3.amazonaws.com',

  ////// PROD //////
  landingDomain: 'https://goodworkhub.com/',
  baseDomain: 'https://goodworkhub.com/',
  appDomain: 'https://app.goodworkhub.com/',
  apiEndPoint: 'https://api.goodworkhub.com/',
  awsPublicAssetsURL: 'https://gwh-static-css-js-files.s3.amazonaws.com',

  firebaseConfig: {},
};
