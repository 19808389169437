import { create, NETWORK_ERROR } from 'apisauce'

import DataStore from './DataStore';
import config from '../Config';
import Emitter from './Emitter';
const HTTP_RESP_CODE_NOT_AUTORIZED = 401;

class NotAuthenticated extends Error {

}

class NotInitialized extends Error {

}

class Api {
  constructor() {
    this.api = null;
    this.authApi = null;

    this.authToken = null;
    this.extraData = {};

    this.deviceId = "web";
    this.version = "1.2.3"; //process.env.npm_package_version;

    this.commonHeaders = {
      "X-GWH-AppVersion": this.version,
      "X-GWH-AppId": config.appId
    };

    try {
      this.initAuth();
    } catch {
      this.init();
    }
  }

  init() {
    if (this.api === null) {
      this.api = create({
        baseURL: config.apiEndPoint,
        headers: { Accept: "application/json", ...this.commonHeaders }
      });
    }
    return this.api;
  }

  initAuth(token, data) {
    if (!token && !this.authToken) {
      token = DataStore.get("access_token");
      this.authToken = token;
    } else if (token) {
      this.authToken = token;
      DataStore.set("access_token", token);
    }

    if (data && Object.keys(data).length > 0) {
      this.extraData = data;
    }

    if (!this.authToken) {
      Emitter.emit("notAuthorizedForceSignout");
      throw new NotAuthenticated("Not Authenticated to init");
    }
    this.api = create({
      baseURL: config.apiEndPoint,
      headers: { Accept: "application/json", ...this.commonHeaders }
    });

    this.authApi = create({
      baseURL: config.apiEndPoint,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.authToken}`,
        ...this.commonHeaders
      }
    });
  }

  setExtraData(data) {
    this.extraData = data;
  }

  getApi() {
    if (this.api === null) {
      this.init();
    }
    return this.api;
  }

  logout() {
    this.extraData = {};
    this.authToken = null;
    this.authApi = null;
    let token = DataStore.set("access_token", "");
  }

  getAuthApi() {
    if (this.authApi === null) {
      throw new NotAuthenticated("Not Authenticated to get");
    }

    return this.authApi;
  }

  post(api, data, attemptCount = 1) {
    return this.postInner(api, data, attemptCount, false);
  }

  put(api, data, attemptCount = 1) {
    return this.putInner(api, data, attemptCount, false);
  }

  delete(api, data, attemptCount = 1) {
    return this.deleteInner(api, data, attemptCount, false);
  }

  postNonAuth(api, data, attemptCount = 1) {
    return this.postInner(api, data, attemptCount, true);
  }

  postInner(api, data, attemptCount, isPublic, promiseObject, promise) {
    promiseObject = promiseObject || { resolve: null, reject: null };

    promise =
      promise ||
      new Promise((resolve, reject) => {
        promiseObject.resolve = resolve;
        promiseObject.reject = reject;
      });

    let apiObj = this.api;
    if (!isPublic) {
      try {
        apiObj = this.getAuthApi();
      } catch (e) {
        promiseObject.reject({ message: "Not authorized" });
      }
    }

    if (attemptCount-- > 0) {
      apiObj
        .post(api, data)
        .then((response) => {
          if (response.ok) {
            promiseObject.resolve(response);
          } else if (response.problem === NETWORK_ERROR) {
            if (attemptCount < 1) {
              promiseObject.reject({ message: "Maximum retry count exceeded" });
            } else {
              setTimeout(
                () => this.postInner(
                    api,
                    data,
                    attemptCount,
                    isPublic,
                    promiseObject,
                    promise
                  ),
                1000
              );
            }
          } else if (response.status === HTTP_RESP_CODE_NOT_AUTORIZED) {
            this.initAuth();
            Emitter.emit("notAuthorizedForceSignout");
            // EventRegister.emit('ToggleToast', { message: 'Session Expired!!!', blockUi: true, visible: true });
            // EventRegister.emit('ToggleSpinner', { message: 'Logging out!!!', visible: true });

            promiseObject.reject(response);
          } else {
            promiseObject.reject(response);
          }
        })
        .catch((error) => {
          if (attemptCount < 1) {
            promiseObject.reject(error);
          } else {
            this.postInner(
              api,
              data,
              attemptCount,
              isPublic,
              promiseObject,
              promise
            );
          }
        });
    }

    return promise;
  }

  putInner(api, data, attemptCount, isPublic, promiseObject, promise) {
    promiseObject = promiseObject || { resolve: null, reject: null };

    promise =
      promise ||
      new Promise((resolve, reject) => {
        promiseObject.resolve = resolve;
        promiseObject.reject = reject;
      });
    let apiObj = this.api;
    if (!isPublic) {
      try {
        apiObj = this.getAuthApi();
      } catch (e) {
        promiseObject.reject({ message: "Not authorized" });
      }
    }

    if (attemptCount-- > 0) {
      apiObj
        .put(api, data)
        .then((response) => {
          if (response.ok) {
            promiseObject.resolve(response);
          } else if (response.problem === NETWORK_ERROR) {
            if (attemptCount < 1) {
              promiseObject.reject({ message: "Maximum retry count exceeded" });
            } else {
              setTimeout(
                () => this.putInner(
                        api,
                        data,
                        attemptCount,
                        isPublic,
                        promiseObject,
                        promise
                      ),
                    1000
              );
            }
          } else if (response.status === HTTP_RESP_CODE_NOT_AUTORIZED) {
            this.initAuth();
            Emitter.emit("notAuthorizedForceSignout");

            promiseObject.reject(response);
          } else {
            promiseObject.reject(response);
          }
        })
        .catch((error) => {
          if (attemptCount < 1) {
            promiseObject.reject(error);
          } else {
            this.putInner(
              api,
              data,
              attemptCount,
              isPublic,
              promiseObject,
              promise
            );
          }
        });
    }

    return promise;
  }

  deleteInner(api, data, attemptCount, isPublic, promiseObject, promise) {
    promiseObject = promiseObject || { resolve: null, reject: null };

    promise =
      promise ||
      new Promise((resolve, reject) => {
        promiseObject.resolve = resolve;
        promiseObject.reject = reject;
      });

    let apiObj = this.api;
    if (!isPublic) {
      try {
        apiObj = this.getAuthApi();
      } catch (e) {
        promiseObject.reject({ message: "Not authorized" });
      }
    }

    if (attemptCount-- > 0) {
      apiObj
        .delete(api, data)
        .then((response) => {
          if (response.ok) {
            promiseObject.resolve(response);
          } else if (response.problem === NETWORK_ERROR) {
            if (attemptCount < 1) {
              promiseObject.reject({ message: "Maximum retry count exceeded" });
            } else {
              setTimeout(
                () => this.deleteInner(
                    api,
                    data,
                    attemptCount,
                    isPublic,
                    promiseObject,
                    promise
                  ),
                1000
              );
            }
          } else if (response.status === HTTP_RESP_CODE_NOT_AUTORIZED) {
            this.initAuth();
            Emitter.emit("notAuthorizedForceSignout");
            promiseObject.reject(response);
          } else {
            promiseObject.reject(response);
          }
        })
        .catch((error) => {
          if (attemptCount < 1) {
            promiseObject.reject(error);

          } else {
            return this.deleteInner(
              api,
              data,
              attemptCount,
              isPublic,
              promiseObject,
              promise
            );
          }
        });
    }

    return promise;
  }

  get(api, params, attemptCount = 1) {
    return this.getInner(api, params, attemptCount, false);
  }

  getNonAuth(api, data, attemptCount = 1) {
    return this.getInner(api, data, attemptCount, true);
  }

  getNonAuthWithHeaders(api, data, headers, attemptCount = 1) {
    return this.getInner(api, data, attemptCount, true, null, null, headers);
  }

  getInner(
    api,
    data,
    attemptCount,
    isPublic,
    promiseObject,
    promise,
    headers = {}
  ) {
    promiseObject = promiseObject || { resolve: null, reject: null };

    promise =
      promise ||
      new Promise((resolve, reject) => {
        promiseObject.resolve = resolve;
        promiseObject.reject = reject;
      });

    let apiObj = this.api;
    if (!isPublic) {
      apiObj = this.getAuthApi();
    }

    if (attemptCount-- > 0) {
      apiObj
        .get(api, data, { headers })
        .then((response) => {
          if (response.ok) {
            promiseObject.resolve(response);
          } else if (response.problem === NETWORK_ERROR) {
            if (attemptCount < 1) {
              promiseObject.reject({ response });
            } else {
              setTimeout(
                () => this.getInner(
                    api,
                    data,
                    attemptCount,
                    isPublic,
                    promiseObject,
                    promise
                  ),
                1000
              );
            }
          } else if (response.status === HTTP_RESP_CODE_NOT_AUTORIZED) {
            this.initAuth();
            Emitter.emit("notAuthorizedForceSignout");
            // EventRegister.emit('ToggleToast', { message: 'Session Expired!!!', blockUi: true, visible: true });
            // EventRegister.emit('ToggleSpinner', { message: 'Logging out!!!', visible: true });

            promiseObject.reject(response);
          } else {
            promiseObject.reject(response);
          }
        })
        .catch((error) => {
          if (attemptCount < 1) {
            promiseObject.reject(error);
          } else {
            this.getInner(
              api,
              data,
              attemptCount,
              isPublic,
              promiseObject,
              promise
            );
          }
        });
    }


    return promise;
  }

  handleHttpResonse(reponse, promiseObject) {}
}

const api = new Api();

// Object.freeze(api);

export default api;
