import {
  FETCH_DASHBOARD_ORG_DATA,
  GET_DASHBOARD_NUMBERS,
  GET_DASHBOARD_BANNERS,
  GET_UPCOMING_EVENTS,
  CLEAR_UPCOMING_EVENTS,
  CLEAR_DASHBOARD_DATA,
} from '../../actions/dashboardAction/index';

const initialState = {
  events: [],
  numbers: {},
  banners: [],
  organization: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_ORG_DATA:
      return {
        ...state,
        organization: action.organization,
      };
    case GET_UPCOMING_EVENTS:
      return {
        ...state,
        events: action.events,
      };
    case CLEAR_UPCOMING_EVENTS:
      return {
        ...state,
        events: [],
      };
    case GET_DASHBOARD_NUMBERS:
      return {
        ...state,
        numbers: action.numbers,
      };
    case GET_DASHBOARD_BANNERS:
      return {
        ...state,
        banners: action.banners,
      };
    case CLEAR_DASHBOARD_DATA:
      return initialState;
    default:
      return state;
  }
};

export default dashboardReducer;
