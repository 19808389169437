import { CREATE_DONATION, GET_DONATIONS, CLEAR_DONATIONS, GET_ONE_DONATION, CREATE_EVENT_DONATION } from '../../actions/donationAction';

const initialState = {
    donation: {},
    donations: []
};

const donationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DONATIONS:
            return {
                ...state,
                donations: action.donations
            }
        case CLEAR_DONATIONS:
            return {
                ...state,
                donations: []
            }
        case CREATE_DONATION:
            return {
                ...state,
                donation: action.donation
            };
        case GET_ONE_DONATION:
            return {
                ...state,
                donation: action.donation
            }
        case CREATE_EVENT_DONATION:
            return {
                ...state,
                donation: action.donation
            };
        default:
            return state;
    }
}

export default donationReducer;
