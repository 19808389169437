import {
  GET_ALL_TEAMS,
  GET_TEAMS,
  GET_MEMBERS_BY_TEAM,
  CLEAR_ALL_TEAMS,
  CLEAR_TEAMS,
  CLEAR_SELECTED_TEAM,
  CLEAR_MEMBERS_BY_TEAM,
  GET_SELECTED_TEAM,
} from '../../actions/teamsAction';

const initialState = {
  allTeams: [],
  list: [],
  total: 0,
  membersByTeam: {},
  selectedTeam: {},
};

const TeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEAMS:
      return { ...state, allTeams: action.teamsData.teams };
    case GET_TEAMS:
      return { ...state, list: action.teamsData.teams, total: action.teamsData.total };
    case GET_MEMBERS_BY_TEAM:
      return {
        ...state,
        membersByTeam: { ...state.membersByTeam, [action.teamMembersData.teamPk]: action.teamMembersData.members },
      };
    case GET_SELECTED_TEAM:
      return { ...state, selectedTeam: action.team };
    case CLEAR_SELECTED_TEAM:
      return { ...state, selectedTeam: {} };
    case CLEAR_ALL_TEAMS:
      return { ...state, allTeams: [] };
    case CLEAR_TEAMS:
      return { ...state, list: [], total: 0 };
    case CLEAR_MEMBERS_BY_TEAM:
      const membersByTeamCopy = { ...state.membersByTeam };
      delete membersByTeamCopy[action.teamPk];
      return { ...state, membersByTeam: membersByTeamCopy };
    default:
      return state;
  }
};

export default TeamsReducer;
